export default function ({ store, redirect, route }) {
  if (store.state.user != null && route.name === 'login-via-phone') {
    const backUrl = localStorage.getItem('back_url')
    if (backUrl) {
      redirect(backUrl)
    } else {
      redirect('/')
    }
  } else {
    redirect('/auth/login-via-phone')
  }
}
