const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authOrder'] = require('../middleware/authOrder.js')
middleware['authOrder'] = middleware['authOrder'].default || middleware['authOrder']

middleware['checkPaymentStatus'] = require('../middleware/checkPaymentStatus.js')
middleware['checkPaymentStatus'] = middleware['checkPaymentStatus'].default || middleware['checkPaymentStatus']

middleware['compression'] = require('../middleware/compression.js')
middleware['compression'] = middleware['compression'].default || middleware['compression']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['route-auth'] = require('../middleware/route-auth.js')
middleware['route-auth'] = middleware['route-auth'].default || middleware['route-auth']

middleware['utm'] = require('../middleware/utm.js')
middleware['utm'] = middleware['utm'].default || middleware['utm']

export default middleware
