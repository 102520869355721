// middleware/checkPaymentStatus.js

export default function ({ route, redirect }) {
  if (route.path.startsWith('/orders/') && route.params.orderId) {
    // const paymentStatus = (route.params.id); // Pass route.params.id to checkPaymentStatus function
    if (route.params.paymentStatus !== 'success') {
      redirect('/th/my/orders/' + route.params.orderId)
    }
  }
}
