// server/middleware/compression.js
const compression = require('compression');

// สร้าง middleware ที่บีบอัด
const compressionMiddleware = compression();
export default (req, res, next) => {
  //   compressionMiddleware(req, res, next);
  compressionMiddleware(req, res, () => {
    // ตั้งค่า Cache-Control headers
    res.setHeader('Cache-Control', 'public, max-age=31536000');
    next();
  });
};
