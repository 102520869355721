export default function (context) {
  // console.log('middleware authhh')
  // console.log('[Middleware] Just Auth', context.store.getters.isAuthenticated)
  // console.log(context.i18n.locale, context.route.path)
  // console.log('debuggg', context.route.path, context.store.getters.isAuthenticated)
  if (context.route.path !== '/' + context.i18n.locale + '/auth/login-via-phone') {
    if (!context.store.getters.isAuthenticated &&
      (context.route.path.includes('/' + context.i18n.locale + '/my') !== false || context.route.path.includes('/' + context.i18n.locale + '/checkout') === true)) {
      context.route.path.includes('/' + context.i18n.locale + '/auth/login-via-phone?redirect=' + (context.route.fullPath || '/'))
    }
  }
}
