const ignorePath = []

export default function ({ isHMR, app, store, route, params, error, redirect }) {
  const defaultLocale = store.state.locale || app.i18n.fallbackLocale
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) { return }
  // Get locale from params
  const locale = params.lang || defaultLocale
  if (!store.state.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  // Set locale
  store.commit('setLang', locale)
  app.i18n.locale = store.state.locale
  if (route.fullPath.indexOf('/' + locale) <= -1 && !ignorePath.includes(route.name)) {
    const newRedirect = `/${locale}${route.fullPath}`
    return redirect(
      newRedirect
    )
  }
}
