export default async function ({ $router, route, context, store, $axios, redirect }) {
  // ตรวจสอบว่าผู้ใช้มีสิทธิ์การเข้าถึงหรือไม่
  const isAuthenticated = store.getters.isAuthenticated

  // ดึง orderId จาก route params
  const orderId = route.params.id
  //   const user = store.getters.getUser
  // ตรวจสอบว่าผู้ใช้มีสิทธิ์การดูออเดอร์หรือไม่
  //   const hasPermission = isAuthenticated && checkPermissionToViewOrder(orderId, store.getters.getUser)

  if (!isAuthenticated) {
    context.route.path.includes('/' + context.i18n.locale + '/auth/login-via-phone?redirect=' + (context.route.fullPath || '/'))
  }
  // ถ้าผู้ใช้ไม่มีสิทธิ์
  if (isAuthenticated) {
    try {
      console.log('middleware :: authOrder')
      const { data } = await $axios.$get('orders/' + orderId)
      const user = await $axios.get('me')
      //   console.log('>>> userData <<<', user.data.user)
      //   console.log('***', user.data.user.id, data.user_id, data.user_id !== user.data.id)

      if (user.data.user && data.user_id !== user.data.user.id) {
        // $router.push(this.localeLocation({ name: 'error-404' }))
        redirect({ path: '/error/404' })
      }
    } catch (e) {
      console.log('Error auth order :: ', e.message)
      redirect({ path: '/error/404' })
      //   $router.push(this.localeLocation({ name: 'error-404' }))
      //   redirect('error/404')
    }
  }
}
