import Vue from 'vue';

import {
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  ImagePlugin,
  LinkPlugin,
  SidebarPlugin,
  FormGroupPlugin,
  ButtonPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  NavPlugin,
  DropdownPlugin,
  FormPlugin,
  NavbarPlugin,
  CarouselPlugin,
  SpinnerPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  TooltipPlugin,
  BadgePlugin,
  InputGroupPlugin,
  FormDatepickerPlugin,
  AlertPlugin,
  FormTextareaPlugin,
  FormFilePlugin,
  CollapsePlugin,
  VBTogglePlugin,
  ListGroupPlugin,
  FormTimepickerPlugin,
  BIconX,
  BIconPlay,
  BIconStarFill,
  BIconStarHalf,
  BIconCheck,
  BIconChevronDown,
  BIconChevronLeft,
  BIconChevronRight,
  BIconArrowLeft,
  BIconArrowRight,
  BIconDownload,
  BIconLockFill,
  BIconQuestionCircleFill,
  BIconFilter
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(CardPlugin);
Vue.use(ImagePlugin);
Vue.use(LinkPlugin);
Vue.use(SidebarPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(NavPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(NavbarPlugin);
Vue.use(CarouselPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(TooltipPlugin);
Vue.use(BadgePlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(AlertPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormFilePlugin);
Vue.use(CollapsePlugin);
Vue.use(VBTogglePlugin);
Vue.use(ListGroupPlugin);
Vue.use(FormTimepickerPlugin);

Vue.component('BIconX', BIconX);
Vue.component('BIconPlay', BIconPlay);
Vue.component('BIconStarFill', BIconStarFill);
Vue.component('BIconStarHalf', BIconStarHalf);
Vue.component('BIconCheck', BIconCheck);
Vue.component('BIconChevronDown', BIconChevronDown);
Vue.component('BIconChevronLeft', BIconChevronLeft);
Vue.component('BIconChevronRight', BIconChevronRight);
Vue.component('BIconArrowLeft', BIconArrowLeft);
Vue.component('BIconArrowRight', BIconArrowRight);
Vue.component('BIconDownload', BIconDownload);
Vue.component('BIconLockFill', BIconLockFill);
Vue.component('BIconQuestionCircleFill', BIconQuestionCircleFill);
Vue.component('BIconFilter', BIconFilter);
