const createHmac = require('create-hmac')
export default class ImgProxy {
  constructor (config) {
    this.config = config
  }

  getUrl (url, width, height) {
    if (url === null || typeof url === 'undefined' || url === '') { return }
    const urlSafeBase64 = (string) => {
      return Buffer.from(string)
        .toString('base64')
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
    }

    const hexDecode = hex => Buffer.from(hex, 'hex')
    const sign = (salt, target, secret) => {
      const hmac = createHmac('sha256', hexDecode(secret))
      hmac.update(hexDecode(salt))
      hmac.update(target)
      return urlSafeBase64(hmac.digest())
    }

    const resizingType = 'fill'
    const gravity = 'sm'
    const enlarge = 1
    // const extension = 'png'
    const encodedUrl = urlSafeBase64(url)
    // const path = `/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}.${extension}`
    // const path = `/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}`
    // imgProxy v2
    const path = `/rs:${resizingType}:${width}:${height}:${enlarge}/g:${gravity}/${encodedUrl}`

    const signature = sign(this.config.salt, path, this.config.key)
    // console.log('1. urlBFencode', url)
    // console.log('2. encodeURL', encodedUrl)
    // console.log('3. path', path)
    // console.log('4. signature', signature)
    // console.log('5. url', `${this.config.cdn}/${signature}${path}`)
    // console.log('-----------------------------')
    return `${this.config.cdn}/${signature}${path}`
  }
}
