import jsCookie from 'js-cookie'
export default function (context) {
  const userData = jsCookie.get('userData')
  if (context.route.path !== '/' + context.i18n.locale + '/auth/login-via-phone') {
    if (!context.store.getters.isAuthenticated &&
      context.route.path.includes('/' + context.i18n.locale + '/my') !== false) {
      context.route.path.includes('/' + context.i18n.locale + '/auth/login-via-phone?redirect=' + (context.route.fullPath || '/'))
    }
  } else if (userData !== null) {
    context.redirect('/' + context.i18n.locale)
  }
}
